@import "bulma";
@import "~bulma-switch";

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
}

.numeration-text {
  display: flex;
  align-items: flex-start; /* wyrównanie numeracji i tekstu do góry */
}

.numeration-text-strong {
  margin-right: 2rem; /* Odstęp między numerem a tekstem */
}

.numeration-text p {
  line-height: 1.5;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.font-anton {
  font-family: "Anton", sans-serif;
}

.font-antonio {
  font-family: "Antonio", sans-serif;
  letter-spacing: 0.05rem;
}

.columns {
  margin-left: 0rem;
  margin-right: 0rem;
  margin-top: 0rem;
}

.heroNotGame {
  padding-top: 8rem;
}

.turnstile-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  align-items: center;
  display: flex;
}

.navbar.navbar-color-background {
  position: absolute;
  background-color: rgb(255, 255, 255);
  top: 2%;
  left: 10%;
  right: 10%;
  border-radius: 10px;
}

.navbar.navbar-white-background {
  position: absolute;
  background-color: rgb(220, 220, 220);
  top: 2%;
  left: 10%;
  right: 10%;
  border-radius: 10px;
}

.navbar.navbar-not-display {
  display: none;
}

a.navbar-item:hover,
.navbar-instrukcja:hover {
  color: white;
  background-color: #9a9a9a;
}

.cartIcon {
  position: relative;
  width: 30%;
  justify-content: flex-end;
  display: flex;
}

.modal-card {
  border-radius: 15px;
}

/* element.style {
  transform: translate(0, 50%);
  translate: transform(25, 25);
  top: 20%;
} */

/* .modal-card,
.modal-content {
  margin: 0 auto;
  width: 90%;
} */

.lobbyPage {
  margin-top: 7rem;
}

.winnersPage {
  margin-top: 4rem;
}

.notification {
  padding: 2rem;
}

.payment-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.numberCart {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(25%, 25%);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: red;
  color: white;
  border: 1px solid red;
}

.heroGame {
  position: relative;
  width: 100%;
  height: 100vh;
  height: 100dvh;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0); /* Initial position */
  }
  50% {
    transform: translateY(-20px); /* Jump up */
  }
}

.world {
  position: absolute;
  bottom: 20%;
  z-index: 1;
  right: 5.5%;
}

.world.animated {
  animation: jump 2s infinite;
}

.game-buttons {
  position: absolute;
  bottom: 2%;
  width: 100%;
  z-index: 2;
}

.button-instructions-modal {
  margin-bottom: 0;
  position: absolute;
  top: 10%;
  /* height: 60px;
  width: 60px; */
  z-index: 2;
  /* border-radius: 50%; */
  left: 50%;
}

.modal-content.instructions p {
  margin-left: 1rem;
  margin-bottom: 2rem;
}

/* .button-go-back-home {
  border-radius: 5px;
  margin: 1rem;
} */

.button-summary {
  /* background-image: linear-gradient(to right, #016eff, #016eff); */
}

.button-summary:hover {
  color: black;
  background-color: rgb(51, 215, 70);
  background-image: none;
  /* color: rgb(255, 255, 255); */
}

.button-markers {
  color: white;
  background-color: #745ec3;
}

.button-markers:hover {
  background-color: rgb(217, 255, 0);
  color: black;
}

.button-cart {
  color: white;
  background-color: rgb(128, 128, 128);
  cursor: default;
  font-size: 0.75rem;
  border-radius: 2px;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
}

.leaflet-container:hover {
  color: black !important;
  cursor: crosshair;
  /* width: 34rem;
  height: 28rem;
  border-top-left-radius: 10px; */
}

.modal-card-head {
  background-color: #fff;
  border-top: none;
  border-bottom: none;
  justify-content: space-around;
}
.modal-card-foot {
  background-color: #fff;
  border-top: none;
  border-bottom: none;
  justify-content: space-around;
  /* margin-top: 2rem;
  margin-bottom: 2rem; */
  display: flex;
  flex-direction: row;
  align-items: center;

  flex-wrap: wrap;
  height: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.homeBanner {
  position: relative;
  /* width: 100%;  chyba nie jest konieczne, sprawdzic*/
  height: 100svh;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
}

.homeContainer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.home-image-banner {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.section.text-on-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  min-height: 100vh;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  margin: auto;
  /* padding-top: 5rem; */
  /* padding-left: 8rem;
  padding-right: 8rem; */
  /* padding-bottom: 1rem; */
}

.banner-text {
  margin-bottom: 0.5rem;
  font-size: 3.5rem;
  /* line-height: 1.5; */
}
.banner-text2 {
  margin-bottom: 1.5rem;
  font-size: 3.5rem;
  /* line-height: 1.5; */
}

.number.mainPage {
  margin-right: 0;
}

.shelterBox {
  background-image: linear-gradient(to right, #62a6ff, #4512ff);
  background-size: cover;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 6px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
}

.shelter-image {
  background-image: url(/src/assets/images/animal-shelter\ 1500-947.jpg);

  background-size: cover;
  background-position: center;
  /* min-height: fit-content; */
  border-radius: 6px;
  margin: 0.75rem;
}

.winner-box {
  margin-top: 3rem;
}

.description-article {
  color: white;
  /* background-image: linear-gradient(to right, #d470f0, #230984); */
  background-image: linear-gradient(to right, #62a6ff, #4512ff);
}

.timer-box {
  background-image: linear-gradient(to right, #4394fe, #3700ff);
  border-radius: 4px;
  height: 5rem;
  display: inline-table;
  width: 100%;
  /* height: 80px; */
  padding: 1rem 2rem 1rem 2rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
}

.timer-text {
  font-size: 1.5rem;
  color: white;
  font-weight: 800;
}

.timer-digits {
  font-size: 2rem;
  color: #00ffd9;
  font-weight: 600;
}

.timer-label {
  font-size: 1rem;
  color: white;
  font-weight: 800;
}

.button-box-cart {
  margin-bottom: 5%;
}

.button-box-finalPage {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.button-link {
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  text-decoration: none;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

.button-link:hover {
  color: rgb(156, 156, 156);
}

.icons-box {
  display: flex;
  justify-content: flex-end;
}

.buttons-on-map {
  display: flex;
  background: none;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  z-index: 999;
}

.button-revert-marker {
  box-shadow: 2px 2px 2px #000000;
}

.button-revert-marker:hover {
  box-shadow: 2px 2px 2px #000000;
}

.button-minimalize-map {
  box-shadow: 2px 2px 2px #000000;
}

.button-map-close {
  cursor: pointer;
  z-index: 2;
}

.button-map-close:hover {
}

.div-with-map-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* NOT USED FOR NOW */
.button-map-close-shadow {
  position: absolute;
  width: 13rem;
  height: 8.5rem;
  background: linear-gradient(to right, #d470f0, #230984);
  transform: translate(5px, 5px); /* Adjusted transform */
  border-radius: 1rem;
  z-index: -1;
  box-shadow: 6px 6px 7px -2px rgba(0, 0, 0, 0.2);
}

.button-map-close-icon {
  width: 13rem;
  height: 8.5rem;
  border-radius: 1rem;
  box-shadow: 2px 2px 2px #000000;
}

/* @keyframes enlarge-and-shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08); 
  }
  100% {
    transform: scale(1); 
  }
} */

.close-icon {
  font-size: 24px;
}

.map-button-columns {
  position: relative;
}

.hero-overlay {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.7;
  z-index: 0;
}

.custom-button {
  /* background-image: linear-gradient(to right, #d545fd, #653bfd); */
  /* background-image: linear-gradient(to right, #d470f0, #230984); */
  background-color: #016eff;
  color: white;
  border-style: none;
  border-radius: 10px;
  padding: 1.7rem 3rem 1.7rem 3rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
}

.custom-button:hover {
  background-color: #016eff;
  color: rgb(0, 0, 0);
}

.button-custom-icon {
  margin-left: 20px;
}

.buttonSection {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.promo-button {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.winners-button {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

footer {
  margin-top: auto;
}

.footer-not-display {
  display: none;
}

.footer a,
.footer p,
.futter button {
  /* color: #e6e6e6; */
}

/* .footer-multiline {
  margin: 0;
} */

.emailConfirmationImg {
  height: 160px;
  margin: auto;
}

.react-datepicker-wrapper {
  width: 100%;
}

.newsletter {
  margin-right: 15%;
  margin-left: 15%;
}

.table-container {
  /* overflow-x: auto; */
}

.btn-primary {
  border-radius: 8px;
  background-color: #404040;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border-color: #404040;
  width: 14rem;
  height: 4rem;
}

.btn-primary:hover {
  color: #fff;
  background-color: black;
}

.btn-secondary {
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  border-color: #404040;
  margin-right: 0 !important;
  width: 14rem;
  height: 4rem;
}

.btn-secondary:hover {
  color: #fff;
  background-color: black;
}

.btn-cart-summar-accept {
  margin-bottom: 1rem;
}

.ql-editor {
  border: 1px solid #ccc;
}

.table-games {
  overflow-wrap: anywhere;
}

/* co to robi? */
#map {
  position: static !important;
}

/* co to robi? */
#map
  > div:nth-child(7)
  > div:nth-child(9)
  > div
  > div
  > div
  > button:nth-child(2) {
  size: 5rem;
}

.reports {
  text-align: left;
}

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.pdf-scroll-container {
  width: 100%;
  overflow-y: auto; /* Allow vertical scrolling */
}
.pdf-page {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  text-align: center;
}

/* USUWANIE LOGO GOOGLE */
/* #map div.gm-style div div a {
  display: none !important;
}


a[href^="https://maps.google.com/maps"], a[href^="https://www.google.com/maps"], a[href^="https://www.google.com/maps"]
{
  display: none !important;
} */

/* MEDIA QUERY */

@media (max-width: 768px) {
  .pdf-container {
    padding: 10px;
  }

  .pdf-scroll-container {
    max-width: 100%;
  }

  .pdf-page {
    width: 100%; /* Make the PDF page fit the screen */
  }
  .navbar-menu {
    background-color: transparent;
  }

  .cartFormColumns {
    flex-direction: column-reverse;
    display: flex;
  }

  .section.text-on-banner {
    padding: 2rem 1rem 0rem 1rem;
  }

  /* chyba zbędne? */
  /* .title.has-text-white.has-text-left {
    font-size: 1.8rem;
  }
  } */

  /* chyba zbędne? */
  /* 
  .has-text-white.has-text-left {
    font-size: 1.2rem;
  } */

  .banner-text {
    margin-bottom: 0.5rem;
    font-size: 3rem;
    line-height: 1.5;
  }
  .banner-text2 {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    line-height: 1.5;
  }

  .timer-box {
    padding: 1rem 2rem 1rem 2rem;
  }

  .numeration-text {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;

    margin-left: 0rem;
  }

  .timer {
    display: flex;
  }

  .timer-text {
    font-weight: 600;
    margin-bottom: 0.5rem;
    padding: 0;
  }

  .leaflet-container {
    width: 100% !important;
    border-top-right-radius: 10px;
    color: black !important;
    cursor: crosshair;
  }

  .leaflet-container:hover {
    width: 100% !important;
    border-top-right-radius: 10px;
    color: black !important;
    cursor: crosshair;
  }

  .world {
    bottom: 17%;
    width: 95%;
    right: 2.5%;
    left: 2.5%;
  }

  .shelter-image {
    height: 250px;
  }

  .table-games {
    overflow-wrap: initial;
    overflow-x: scroll;
  }

  /* zrobione stricte dla CookiesModalPreferences */
  .table td,
  .table th {
    padding: 0;
  }

  .button-markers {
    color: white;
    height: 1.5rem !important;
    background-color: rgb(97, 97, 97);
  }

  .button-markers:active {
    color: white;
    height: 1.5rem !important;
    background-color: rgb(97, 97, 97);
  }

  .button-cart {
    color: white;
    height: 1.5rem !important;
    background-color: grey;
    cursor: default;
    font-size: 0.75rem;
  }

  .btn-primary {
    margin-bottom: 1rem;
  }

  .button-box-finalPage {
    flex-direction: column-reverse;
  }

  .modal {
    width: 100vw;
  }

  .modal-card {
    width: 90%;
  }

  .modal-card-foot {
    flex-direction: column-reverse;
    /* align-items: stretch; */
  }
}
